<template>
  <div id="app">
    <Header />
    <router-view />
    <transition name="fade">
      <MintDialog v-if="$store.state.mintPrompt" />
      <MintDialogWhitelist v-if="$store.state.mintPromptWhitelist" />
    </transition>
    <Footer />
  </div>
</template>
<script>
import Footer from "./components/Footer";
import Header from "./components/Header";
import MintDialog from "./components/ui/MintDialog";
import MintDialogWhitelist from "./components/ui/MintDialogWhitelist";
export default {
  components: {
    Header,
    MintDialog,
    Footer,
    MintDialogWhitelist,
  },
};
</script>
<style lang="scss">
.container {
  max-width: 90%;
  margin: 0 auto;
  position: relative;
}
body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background: linear-gradient(213deg, #151424, #151424, #151424);
  background-size: 600% 600%;

  &:after {
    position: fixed;
    top: 0;
    left: 0;
    content: "";
    background: url("~@/assets/bgseals.jpg");
    background-size: cover;
    height: 100%;
    width: 100%;
    opacity: 0.2;
    z-index: -1;
  }
}
h1,
h2,
h3 {
  margin-block-start: 0;
  margin-block-end: 0;
}

@-webkit-keyframes Bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes Bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes Bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (min-width: 760px) {
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 90%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1366px) {
  .container {
    max-width: 1280px;
    margin: 0 auto;
  }
}
</style>
