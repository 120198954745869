<template>
  <div>
    <div class="ol" @click.self="closePrompt"></div>
    <div v-if="!$store.state.txStarted" class="md">
      <div class="md-head">
        <h1>Sappy Seals Mint</h1>
        <h2>0.06 ETH Per Sappy Seal</h2>
      </div>
      <div class="md-body">
        <div class="sappy-slider">
          <input
            v-model="mintAmt"
            type="range"
            min="1"
            max="20"
            value="5"
            class="sappy-range"
            :class="mintAmt > 5 ? 'shake' : ''"
          />
        </div>
        <h2>
          {{ mintAmt }} To mint

          <span> {{ (mintAmt * 0.06).toFixed(2) }} ETH </span>
        </h2>
      </div>
      <div class="md-footer">
        <Button
          @btnClicked="attemptConnect"
          :text="connectButtonText"
          @click="attemptConnect"
        />
        <Button
          :text="`Mint ${mintAmt} Seal${mintAmt == 1 ? '' : 's'}`"
          @btnClicked="DoMint"
          :class="validInput ? 'activeBtn' : ''"
        />
      </div>
    </div>
    <div v-if="$store.state.txStarted" class="tx-wait">
      <img v-if="!$store.state.txComplete" src="@/assets/slider.png" />
      <h1 v-if="!$store.state.txComplete">Transaction pending...</h1>
      <h1 class="tx-complete" v-if="$store.state.txComplete">
        Transaction complete!
      </h1>
      <a :href="'https://etherscan.io/tx/' + $store.state.txHash"
        >View transaction on Etherscan</a
      >
      <div class="close" @click="closePrompt">Close</div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import Button from "./Button.vue";
export default {
  components: {
    Button,
  },
  computed: {
    validInput() {
      return (
        this.mintAmt >= 1 &&
        this.mintAmt <= 20 &&
        this.$store.state.accounts !== null
      );
    },
    connectButtonText() {
      let vm = this;
      return vm.$store.state.accounts !== null
        ? vm.$store.state.accounts[0].substring(0, 5) +
            "..." +
            vm.$store.state.accounts[0].substring(
              vm.$store.state.accounts[0].length,
              vm.$store.state.accounts[0].length - 5
            )
        : "Connect";
    },
  },
  methods: {
    closePrompt() {
      let vm = this;
      vm.$store.state.mintPrompt = !vm.$store.state.mintPrompt;
      vm.$store.state.txStarted = false;
      vm.$store.state.txComplete = false;
      vm.$store.state.txFail = false;
    },
    attemptConnect() {
      let vm = this;
      if (vm.$store.state.provider == null) {
        vm.$store.dispatch("ConnectWallet");
      } else {
        console.log("dcing");
        vm.$store.dispatch("DisconnectWallet");
      }
    },
    async DoMint() {
      console.log(this.mintAmt);
      let vm = this;
      if (
        this.$store.state.provider !== null &&
        this.mintAmt !== null &&
        this.$store.state.accounts !== null &&
        this.validInput
      ) {
        const ddContract = new this.$store.state.web3.eth.Contract(
          vm.$store.state.abi,
          vm.$store.state.contractAddress
        );

        const weiValue = Web3.utils.toWei(
          (this.mintAmt * 0.06).toString(),
          "ether"
        );
        vm.$store.state.txStarted = true;

        let method = ddContract.methods.mintSeal(this.mintAmt);
        let opts = {
          from: this.$store.state.accounts[0],
          value: weiValue,
        };
        let gasCost = await method.estimateGas(opts);
        console.log("gas cost is");
        console.log(gasCost);

        ddContract.methods
          .mintSeal(this.mintAmt)
          .send({
            gas: parseInt(1.2 * gasCost),
            from: this.$store.state.accounts[0],
            value: weiValue,
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
            vm.$store.state.txHash = hash;
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("confirmation number: " + confirmationNumber);
            console.log(receipt);
          })
          .on("receipt", function (receipt) {
            console.log("tx receipt...");
            console.log(receipt);
            vm.$store.state.txComplete = true;
          })
          .on("error", function (error) {
            // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            vm.$store.state.txFail = true;
            console.error("Sappy Seals TX Failure");
            console.error(error);
          });
      }
    },
  },
  mounted() {},
  data() {
    return {
      mintAmt: 3, //Default amount of seals to mint
      web3Modal: null,
      provider: null,
      web3: null,
      accounts: null,
      userAccount: null,
      abi: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ol {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(12px);
  transition: 0.5s ease;
  z-index: 1;
}
.tx-wait {
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  backdrop-filter: blur(6px);
  padding: 2rem;
  box-shadow: 0px 0px 50px rgb(255 255 255 / 12%) inset, 0px 40px 50px #0000006e;
  transition: 0.5s ease;
  transition-delay: 0.5s;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 540px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
  img {
    max-width: 4rem;
    animation: spinner 1s linear infinite;
  }
  .tx-complete {
    color: darkseagreen;
  }
  .close {
    color: white;
    padding: 1rem 2rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    cursor: pointer;
    margin: 1rem;
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}
.md {
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  backdrop-filter: blur(6px);
  padding: 2rem;
  box-shadow: 0px 0px 50px rgb(255 255 255 / 12%) inset, 0px 40px 50px #0000006e;
  transition: 0.5s ease;
  transition-delay: 0.5s;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  margin: 0 auto;
  box-sizing: border-box;
  z-index: 1;
  .md-footer {
    margin: 2rem 0;

    .button {
      margin: 1rem auto;
      min-width: 50%;
      &:last-child {
        opacity: 0.5;
      }
    }
  }
  .md-head {
    text-align: center;
    h1,
    h2 {
      font-family: "Press Start 2P";
    }
    h1 {
      font-size: 1.35rem;
      line-height: 1.5rem;
    }
    h2 {
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.75rem;
      line-height: 2rem;
    }
  }
  .md-body {
    text-align: center;
    h2 {
      color: rgba(255, 255, 255, 1);
      font-size: 1rem;
      line-height: 2rem;
    }
    .sappy-slider {
      width: 100%;
      margin: 2rem 0;
      input {
        width: 100%;
      }
      .sappy-range {
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        border-radius: 5px;
        background: #d3d3d375;
        outline: none;
        opacity: 1;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
      }

      .sappy-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 5rem;
        height: 5rem;
        border: 0;
        background: url("~@/assets/slider.png");
        cursor: pointer;
        background-size: cover;
      }

      .sappy-range::-moz-range-thumb {
        width: 5rem;
        height: 5rem;
        border: 0;
        background: url("~@/assets/slider.png");
        cursor: pointer;
        background-size: cover;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .md {
    max-width: 560px;
    margin: 0 auto;
  }
}
</style>
