<template>
  <footer>
    <nav class="container">
      <div class="grid-item">
        <img class="brand" src="@/assets/logoseal.png" alt="" />
      </div>
      <div class="grid-item">
        <div class="socials">
          <a href="https://discord.gg/aNpmbHChVy"
            ><img src="@/assets/discord.png"
          /></a>
          <a href="https://twitter.com/SappySealsNFT"
            ><img src="@/assets/twitter.png"
          /></a>
          <a href="https://opensea.io/collection/sappy-seals/"
            ><img src="@/assets/opensea.png"
          /></a>
          <p>Copyright &copy; Sappy Seals 2021 - All Rights Reserved.</p>
        </div>
      </div>
    </nav>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
h1 {
  color: $primaryColour;
}

footer {
  z-index: 5;
  padding: 0.5rem 0;

  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  left: 0;
  right: 0;
  nav {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    .brand {
      max-width: 5rem;
      text-align: left;
      border-radius: 75px;
      border: 3px solid white;
      position: relative;
      top: 2px;
    }
    .socials {
      text-align: center;
      margin-top: 1rem;
      p {
        color: rgba(255, 255, 255, 0.2);
        font-size: 0.8rem;
      }
      a {
        margin: 0 1rem;
        border: 0;
        img {
          max-width: 2rem;
        }
      }
    }
  }
}
</style>
