<template>
  <div class="bf-base">
    <div class="crack-floor"></div>
    <div class="bf">
      <div class="collection-wrap container" v-scroll-reveal="{ delay: 450 }">
        <div class="bf-head">
          <h1>The Collection</h1>
          <h2>Which seal will swim into your wallet?</h2>
        </div>
      </div>
      <div
        class="collection-grid"
        v-scroll-reveal="{ delay: 450 }"
        v-for="(row, i) in collection"
        v-bind:key="i"
      >
        <InfiniteSlideBar
          class="collection-grid"
          :barStyle="{ background: 'transparent', padding: '5px 0' }"
          :direction="i == 1 ? 'reverse' : 'normal'"
          :duration="'60s'"
        >
          <div v-for="asset in row" v-bind:key="asset._id" class="scroll-row">
            <img class="scroll-img" :src="asset.img" />
          </div>
        </InfiniteSlideBar>
      </div>
      <OneOfOne
        class="container"
        :headings="{
          title: 'Seal Team Six',
          subtitle: 'The team.',
        }"
        :picData="sealTeamSix"
        :animation="true"
        :floatNum="1"
      />
      <OneOfOne
        class="container"
        :headings="{ title: '1/1s', subtitle: 'The exclusive collection.' }"
        :picData="oneOfOnes"
        :animation="false"
        :floatNum="2"
      />
      <Roadmap class="container" />
    </div>
  </div>
</template>

<script>
import InfiniteSlideBar from "vue-infinite-slide-bar";
import Roadmap from "./Roadmap.vue";
import OneOfOne from "./OneOfOne.vue";
export default {
  name: "BelowFold",
  components: {
    InfiniteSlideBar,
    Roadmap,
    OneOfOne,
  },
  mounted() {},
  data() {
    return {
      collection: [
        [
          {
            _id: 0,
            name: "#123",
            img: require("@/assets/seals/1.png"),
          },
          {
            _id: 1,
            name: "#123",
            img: require("@/assets/seals/2.png"),
          },
          {
            _id: 2,
            name: "#123",
            img: require("@/assets/seals/3.png"),
          },
          {
            _id: 3,
            name: "#123",
            img: require("@/assets/seals/4.png"),
          },
          {
            _id: 4,
            name: "#123",
            img: require("@/assets/seals/5.png"),
          },
          {
            _id: 5,
            name: "#123",
            img: require("@/assets/seals/10.png"),
          },
          {
            _id: 6,
            name: "#123",
            img: require("@/assets/seals/7.png"),
          },
          {
            _id: 7,
            name: "#123",
            img: require("@/assets/seals/8.png"),
          },
          {
            _id: 8,
            name: "#123",
            img: require("@/assets/seals/9.png"),
          },
        ],
        [
          {
            _id: 4,
            name: "#123",
            img: require("@/assets/seals/6.png"),
          },
          {
            _id: 5,
            name: "#123",
            img: require("@/assets/seals/11.png"),
          },
          {
            _id: 6,
            name: "#123",
            img: require("@/assets/seals/12.png"),
          },
          {
            _id: 7,
            name: "#123",
            img: require("@/assets/seals/13.png"),
          },
          {
            _id: 8,
            name: "#123",
            img: require("@/assets/seals/14.png"),
          },
          {
            _id: 9,
            name: "#123",
            img: require("@/assets/seals/15.png"),
          },
          {
            _id: 10,
            name: "#123",
            img: require("@/assets/seals/16.png"),
          },

          {
            _id: 11,
            name: "#123",
            img: require("@/assets/seals/17.png"),
          },
          {
            _id: 12,
            name: "#123",
            img: require("@/assets/seals/18.png"),
          },
        ],
        [
          {
            _id: 8,
            name: "#123",
            img: require("@/assets/seals/19.png"),
          },
          {
            _id: 9,
            name: "#123",
            img: require("@/assets/seals/20.png"),
          },
          {
            _id: 10,
            name: "#123",
            img: require("@/assets/seals/21.png"),
          },

          {
            _id: 11,
            name: "#123",
            img: require("@/assets/seals/22.png"),
          },
          {
            _id: 12,
            name: "#123",
            img: require("@/assets/seals/23.png"),
          },
          {
            _id: 13,
            name: "#123",
            img: require("@/assets/seals/27.png"),
          },
          {
            _id: 14,
            name: "#123",
            img: require("@/assets/seals/26.png"),
          },

          {
            _id: 15,
            name: "#123",
            img: require("@/assets/seals/121.png"),
          },

          {
            _id: 16,
            name: "#123",
            img: require("@/assets/seals/11.png"),
          },
        ],
      ],
      sealTeamSix: [
        {
          _id: 0,
          active: false,
          name: "Seal Team 1 / 6",
          mintable: true,
          mintableText: "Given away",
          img: require("@/assets/seals/s1.png"),
        },
        {
          _id: 1,
          active: false,
          name: "Seal Team 2 / 6",
          mintable: true,
          mintableText: "Given away",
          img: require("@/assets/seals/s2.png"),
        },
        {
          _id: 2,
          active: false,
          name: "Seal Team 3 / 6",
          mintable: true,
          mintableText: "Giveaway",
          img: require("@/assets/seals/s3.png"),
        },
        {
          _id: 3,
          active: false,
          name: "Seal Team 4 / 6",
          mintable: true,
          mintableText: "Giveaway",
          img: require("@/assets/seals/unknown.png"),
        },
        {
          _id: 4,
          active: false,
          name: "Seal Team 5 / 6",
          img: require("@/assets/seals/unknown.png"),
        },
        {
          _id: 5,
          active: false,
          name: "Seal Team 6 / 6",
          img: require("@/assets/seals/unknown.png"),
        },
      ],
      oneOfOnes: [
        {
          _id: 0,
          active: false,
          name: "Caleb Pressley",
          isUrl: true,
          url: "https://twitter.com/calebpressley",
          img: require("@/assets/seals/110.png"),
        },
        {
          _id: 1,
          active: false,
          name: "₿yzantinΞ General",
          isUrl: true,
          url: "https://twitter.com/ByzGeneral",
          img: require("@/assets/seals/122.png"),
        },

        {
          _id: 3,
          active: false,
          name: "rekt_teka$hi",
          isUrl: true,
          url: "https://twitter.com/Rekt_Tekashi",
          img: require("@/assets/seals/118.png"),
        },
        {
          _id: 4,
          active: false,
          name: "CryptoGodJohn",
          isUrl: true,
          url: "https://twitter.com/CryptoGodJohn",
          img: require("@/assets/seals/115.png"),
        },
        {
          _id: 5,
          active: false,
          name: "cryptoΞliv",
          isUrl: true,
          url: "https://twitter.com/ugliestduck",
          img: require("@/assets/seals/116.png"),
        },
        {
          _id: 6,
          active: false,
          name: "SATSDART",
          isUrl: true,
          url: "https://twitter.com/satsdart",
          img: require("@/assets/seals/117.png"),
        },
        {
          _id: 7,
          active: false,
          name: "louis (barneytheboi)",
          isUrl: true,
          url: "https://twitter.com/barneytheboi",
          img: require("@/assets/seals/111.png"),
        },
        {
          _id: 8,
          active: false,
          name: "Fiskantes",
          isUrl: true,
          url: "https://twitter.com/Fiskantes",
          img: require("@/assets/seals/119.png"),
        },
        {
          _id: 9,
          active: false,
          name: "Gainzy",
          isUrl: true,
          url: "https://twitter.com/PepeXBT",
          img: require("@/assets/seals/120.png"),
        },
        {
          _id: 10,
          active: false,
          name: "CL207",
          isUrl: true,
          url: "https://twitter.com/CL207",
          img: require("@/assets/seals/121.png"),
        },
        {
          _id: 11,
          active: false,
          name: "The Crypto Monk",
          isUrl: true,
          url: "https://twitter.com/thecryptomonk",
          img: require("@/assets/seals/112.png"),
        },

        {
          _id: 12,
          active: false,
          name: "AltsQ",
          isUrl: true,
          url: "https://twitter.com/AltsQ",
          img: require("@/assets/seals/123.png"),
        },

        {
          _id: 16,
          active: false,
          name: "Satan",
          isUrl: true,
          url: "https://twitter.com/s8nstan",
          img: require("@/assets/seals/27.png"),
        },
        {
          _id: 14,
          active: false,
          name: "????",
          isUrl: false,
          mintable: true,
          mintableText: "Mintable",
          url: "https://twitter.com/",
          img: require("@/assets/seals/124.png"),
        },

        {
          _id: 15,
          active: false,
          name: "????",
          isUrl: false,
          mintable: true,
          mintableText: "Mintable",
          url: "https://twitter.com/",
          img: require("@/assets/seals/7.png"),
        },

        {
          _id: 17,
          active: false,
          name: "????",
          isUrl: false,
          url: "https://twitter.com/",
          img: require("@/assets/seals/unknown.png"),
        },
        {
          _id: 18,
          active: false,
          name: "????",
          isUrl: false,
          url: "https://twitter.com/",
          img: require("@/assets/seals/unknown.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.bf-base {
  margin-top: 80vh;
}
.crack-floor {
  background: url("~@/assets/ice.png");
  background-size: cover;
  width: 100%;
  height: 100vw;
  background-position: 25% 0%;
  position: relative;
}
.bf {
  position: relative;
  z-index: 1;
  background: linear-gradient(#013378, #141424);
  overflow: hidden;
  text-align: center;
  .bf-head {
    padding-top: 0rem;
    padding-bottom: 5rem;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 900;
    font-family: "Press Start 2P";
    text-shadow: -0.06em 0 red, 0.06em 0 cyan;
    margin-bottom: 1rem;

    line-height: 2rem;
  }
  h2 {
    font-size: 1.25rem;

    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 1rem;
    line-height: 1.2rem;
  }
  .collection-grid {
    //display: grid;
    // grid-template-rows: repeat(4, 1fr);
    transform: rotate(-5deg);
    gap: 1rem;
    .scroll-row {
      display: inline-block;
      padding: 0.25rem 0;
    }
    img {
      padding: 0 0.5rem;
      max-width: 9rem;
      border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    }
  }
}
@media screen and (min-width: 760px) {
}
@media screen and (min-width: 1024px) {
  .bf-base {
    //margin-top: 27vw;
  }
  .bf {
    text-align: left;
    .bf-head {
      padding-top: 10rem;
      padding-bottom: 5rem;
    }
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 1.25rem;
    }
    .collection-grid {
      img {
        padding: 0 0.5rem;
        max-width: 17rem;
        border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
      }
    }
  }
  .crack-floor {
    background: url("~@/assets/ice.png");
    background-size: cover;
    width: 100%;
    height: 42vw;
    position: relative;
  }
}
@media screen and (min-width: 1200px) {
  .bf-base {
    margin-top: 27vw;
  }
}
@media screen and (min-width: 1366px) {
}
@media screen and (min-width: 1600px) {
}
</style>
