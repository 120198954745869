<template>
  <div class="hero" :style="heroPos">
    <div class="entry-hero container" :style="blur">
      <div class="hero-item hero-text">
        <h1>Sappy Seals</h1>
        <h2 v-if="mintText !== 'now'">Mint starts {{ mintText }}</h2>
        <h3 v-if="premintText !== 'now'">Premint starts {{ premintText }}</h3>
        <h2 v-if="mintText == 'now'">Minted {{ mintedSoFar }} / 10,000</h2>
        <div v-if="mintText == 'now'" class="pct-bar">
          <div class="bar" :style="mintProgressBar">
            <img src="@/assets/slider.png" alt="" />
          </div>
        </div>
        <h3>Mint price: 0.06 ETH</h3>
        <div class="btn-group">
          <Button
            :text="'Mint'"
            @btnClicked="startMint(false)"
            :class="mintText == 'now' ? '' : 'inactive'"
          />
          <Button
            v-if="$store.state.whitelistedAccount"
            :text="'Premint'"
            @btnClicked="startMint(true)"
            :class="premintText == 'now' ? '' : 'inactive'"
          />
        </div>
      </div>
      <div class="hero-item"></div>
      <div class="hero-item slider">
        <Carousel />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./ui/Button.vue";
import Carousel from "./ui/Carousel.vue";
import moment from "moment";
import web3 from "web3";

export default {
  name: "HeroSection",
  components: {
    Button,
    Carousel,
  },
  computed: {},
  methods: {
    startMint(premint) {
      let vm = this;
      if (premint) {
        if (vm.premintText !== "now") return;
        vm.$store.state.mintPromptWhitelist =
          !vm.$store.state.mintPromptWhitelist;
      } else {
        if (vm.mintText !== "now") return;
        vm.$store.state.mintPrompt = !vm.$store.state.mintPrompt;
      }
    },
    calcMintTime() {
      let vm = this;
      let mintDate = new Date(Date.UTC(2021, 7, 31, 22, 30));
      let mintDateMoment = moment(mintDate);
      setInterval(() => {
        let now = moment(new Date());
        let dif = moment.duration(mintDateMoment.diff(now));
        if (moment(mintDate).fromNow().includes("ago")) {
          vm.mintText = "now";
        } else {
          vm.mintText = `in ${dif.days()} days ${dif.hours()} hours ${dif.minutes()} minutes ${dif.seconds()} seconds`;
        }
      }, 1000);
    },
    calcPremintTime() {
      let vm = this;
      let mintDate = new Date(Date.UTC(2021, 7, 31, 22, 0));
      let mintDateMoment = moment(mintDate);
      setInterval(() => {
        let now = moment(new Date());
        let dif = moment.duration(mintDateMoment.diff(now));
        if (moment(mintDate).fromNow().includes("ago")) {
          vm.premintText = "now";
        } else {
          vm.premintText = `in ${dif.days()} days ${dif.hours()} hours ${dif.minutes()} minutes ${dif.seconds()} seconds`;
        }
      }, 1000);
    },
    updateScroll() {
      if (this.opac > 1 || this.opac < 0) {
        this.opac = 1;
      }
      this.scrollPosition = window.scrollY;
      this.heroPos = `transform: scale(${
        1 - this.scrollPosition / 2800
      }); z-index:${this.scrollPosition / 8 > 7 ? "0" : "1"}; `;
      this.blur = `opacity:${1 - this.scrollPosition / 700}`;
    },
    async mintProgress() {
      let vm = this;

      const abiReq = await fetch(vm.$store.state.abiAddress);
      const abiRes = await abiReq.json();
      const parsed = await JSON.parse(abiRes.result);
      vm.$store.state.abi = parsed;
      const provider = new web3.providers.HttpProvider(
        vm.$store.state.infuraAddress
      );
      let w3 = new web3(provider);
      const slContract = new w3.eth.Contract(
        parsed,
        vm.$store.state.contractAddress
      );
      vm.mintedSoFar = await slContract.methods.totalSeals().call();
      return vm.mintedSoFar;
    },
  },
  mounted() {
    let vm = this;
    window.addEventListener("scroll", this.updateScroll);
    vm.calcMintTime();
    vm.calcPremintTime();
    vm.mintProgress().then((result) => {
      vm.mintedSoFar = result;
      vm.mintProgressBar = `width: ${(result / 10000) * 100}%`;
    });
  },
  data() {
    return {
      mintText: "Soon",
      premintText: "Soon",
      heroPos: 0,
      scrollPosition: 0,
      blur: 0,
      mintProgressBar: `width: 0%`,
      mintedSoFar: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes progress-bar {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes progress-bar {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes progress-bar {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes shake {
  0% {
    top: -1.5em;
    right: -2em;
  }
  50% {
    top: -1.3em;
    right: -2.5em;
  }
  100% {
    top: -1.5em;
    right: -2em;
  }
}
.hero {
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  position: fixed;
  transition: 0.1s ease;
  margin-top: 12vh;

  z-index: 1;
  .entry-hero {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: row;
    justify-items: center;
    text-align: center;
    h1 {
      font-size: 1.5rem;
      font-weight: 900;
      font-family: "Press Start 2P";
      text-shadow: -0.06em 0 red, 0.06em 0 cyan;
      margin-bottom: 1rem;
      margin-top: 1rem;
      line-height: 2rem;
    }
    h2 {
      font-size: 1.25rem;
      font-family: "Press Start 2P";
      color: rgba(255, 255, 255, 1);
      margin-bottom: 1rem;
      line-height: 1.5rem;
    }
    h3 {
      color: rgba(255, 255, 255, 0.3);
      margin-bottom: 1rem;
    }
    .hero-item {
      &.hero-text {
        .btn-group {
          .button {
            margin: 1rem 0;
          }
        }
        .pct-bar {
          background: rgba(255, 255, 255, 0.2);
          margin-top: 2rem;
          border-radius: 75px;
          .bar {
            height: 0.5rem;
            margin-bottom: 2rem;
            position: relative;
            border-radius: 8px;
            background: linear-gradient(90deg, #0ea51a, #00a3ff, #8d00ff);
            background-size: 600% 600%;

            -webkit-animation: progress-bar 5s ease infinite;
            -moz-animation: progress-bar 5s ease infinite;
            animation: progress-bar 5s ease infinite;
            img {
              max-width: 4rem;
              position: absolute;
              top: -1.5rem;
              right: -2rem;
              -webkit-animation: shake 0.5s ease infinite;
              -moz-animation: shake 0.5s ease infinite;
              animation: shake 0.5s ease infinite;
            }
          }
        }
      }
      &.slider {
        top: -1rem;
        grid-row: 1;
        position: relative;
        left: 0;
      }
    }
  }
}
@media screen and (min-width: 760px) {
  .hero {
    transform: scale(1);
    .entry-hero {
      .hero-item {
        .button {
          margin: 0 auto;
          max-width: 8rem;
        }
        h1 {
          font-size: 3rem;
          line-height: 3rem;
        }
        h2 {
          margin-bottom: 1rem;
        }
        h3 {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .hero {
    .entry-hero {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 0;
      grid-auto-flow: column;
      text-align: left;
      .hero-item {
        &.hero-text {
          .btn-group {
            .button {
              display: inline-block;
              min-width: 8rem;
              margin: 1rem 0.5rem;
              &:first-child {
                margin-left: 0;
              }
            }
          }
          margin-top: 9rem;
          .button {
            margin: 0;
          }
        }
        &.slider {
          grid-row: 2;
        }
        h1 {
          font-size: 4.5rem;
          line-height: 7rem;
        }
        h2 {
          font-size: 1.5rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .hero {
    margin-top: 5rem;
    .entry-hero {
      .hero-item {
        &.slider {
          left: 0%;
        }
        h1 {
          font-size: 4.5rem;
          line-height: 7rem;
        }
        h2 {
          font-size: 1.5rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1366px) {
  .hero {
    margin-top: 10rem;
    .entry-hero {
      .hero-item {
        &.hero-text {
          margin-top: 11rem;
        }
        &.slider {
          left: 0%;
        }
        h1 {
          font-size: 5rem;
        }
        h2 {
          font-size: 1.25rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  .hero {
    .entry-hero {
      .hero-item {
        &.slider {
          //left: 15%;
        }
      }
    }
  }
}
</style>
