<template>
  <div class="OfO container">
    <img
      :src="require(`@/assets/blocks-${floatNum}.png`)"
      :class="`block-float-${floatNum}`"
    />
    <div class="OfO-head">
      <h1>{{ headings.title }}</h1>
      <h2>{{ headings.subtitle }}</h2>
    </div>
    <div class="OfO-items">
      <div
        class="OfO-item"
        v-for="asset in picData"
        v-bind:key="asset._id"
        :class="asset._id == count && !paused ? 'active' : ''"
        @mouseenter="paused = true"
        @mouseleave="paused = false"
      >
        <h2 v-if="!asset.isUrl">{{ asset.name }}</h2>
        <span class="mintable" v-if="asset.mintable">{{
          asset.mintableText
        }}</span>
        <a class="item-link" v-if="asset.isUrl" :href="asset.url"
          ><h2>{{ asset.name }}</h2>
          <img :src="asset.img" />
        </a>
        <img v-if="!asset.isUrl" :src="asset.img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headings: Object,
    picData: Array,
    animation: Boolean,
    floatNum: Number,
  },
  data() {
    return {
      count: this.animation == true ? 0 : null,
      paused: false,
    };
  },
  methods: {
    loopItems() {
      let vm = this;
      setTimeout(async () => {
        if (vm.count == vm.picData.length || vm.count > vm.picData.length) {
          vm.count = 0;
        }
        vm.picData.forEach((item, idx) => {
          if (idx == vm.count) {
            item.active = true;
          } else {
            item.active = false;
          }
        });
      }, 2000);
    },
  },
  mounted() {
    let vm = this;
    if (vm.animation == true) {
      setInterval(() => {
        if (!vm.paused) {
          vm.loopItems();
          vm.count++;
        }
      }, 1000);
    }
  },
};
</script>

<style lang="scss">
@keyframes block-float {
  0% {
    transform: translateY(0%) rotate(0deg);
  }
  50% {
    transform: translateY(10%) rotate(-5deg);
  }
  100% {
    transform: translateY(0%) rotate(0deg);
  }
}
.item-link {
  border-bottom: 0;
}
.OfO {
  margin-top: 12rem;
  position: relative;
  .block-float-1,
  .block-float-2,
  .block-float-3 {
    position: absolute;
    animation: block-float 4s ease-in-out infinite;
  }
  .block-float-1 {
    top: -12rem;
    right: -25%;
    width: 15rem;
  }
  .block-float-2 {
    top: 24rem;
    left: -25%;
    width: 15rem;
    opacity: 0;
    visibility: hidden;
  }
  .block-float-3 {
    top: -6rem;
    right: -25%;
    width: 15rem;
  }
  .OfO-head {
    margin-bottom: 4rem;
    text-align: center;
  }
  .OfO-items {
    display: grid;

    grid-template-columns: repeat(3, 2fr);

    .OfO-item {
      position: relative;
      .mintable {
        background: rgb(0 255 199 / 78%);
        color: rgb(255 255 255);
        padding: 0.2rem 0.6rem;
        border-radius: 50px;
        position: absolute;
        top: 2.7rem;

        font-size: 0.85rem;
        margin: 0 auto;
      }
      margin: 0 -2rem;
      transition: 0.3s ease;
      &.active {
        margin: -1.2rem -2.5rem 0 -2.5rem;
        z-index: 999;
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(6px);
      }
      &:hover {
        margin: -1.2rem -2.5rem 0 -2.5rem;
        z-index: 999;
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(6px);
      }
      padding: 1rem;
      border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
      h2 {
        color: white;
        font-size: 0.75rem;
        text-align: center;
      }
      img {
        max-width: 85%;

        border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;

        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.9);
      }
    }
  }
}

@media screen and(min-width: 769px) {
  .OfO {
    .block-float-1 {
      top: -6rem;
      right: 0;
      width: 15rem;
    }
    .block-float-2 {
      opacity: 1;
      visibility: visible;
    }
    .OfO-items {
      grid-template-columns: repeat(6, 1fr);
      .OfO-item {
        h2 {
          font-size: 1rem;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
