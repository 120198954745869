<template>
  <div class="roadmap">
    <div class="rm-head">
      <h1>Roadmap</h1>
      <h2>Wow such roadmap.</h2>
    </div>
    <div style="display: none" class="rm-items" v-if="false">
      <div class="rm-item" v-scroll-reveal="{ delay: 250 }">
        <h1>25%</h1>
        <div class="pct-bar">
          <div class="bar b25">
            <img src="@/assets/slider.png" alt="" />
          </div>
        </div>
        <p>
          We'll be donating $10K to the
          <a href="https://theoceancleanup.com/donate/">Ocean Cleanup</a>
        </p>
      </div>
      <div class="rm-item" v-scroll-reveal="{ delay: 350 }">
        <h1>50%</h1>
        <div class="pct-bar">
          <div class="bar b50">
            <img src="@/assets/slider.png" alt="" />
          </div>
        </div>
        <p>We'll be launching Sappy Seal merch!</p>
      </div>
      <div class="rm-item" v-scroll-reveal="{ delay: 450 }">
        <h1>75%</h1>
        <div class="pct-bar">
          <div class="bar b75">
            <img src="@/assets/slider.png" alt="" />
          </div>
        </div>
        <p>
          We'll be donating $30K (extra) to the
          <a href="https://theoceancleanup.com/donate/">Ocean Cleanup</a>
        </p>
      </div>
      <div class="rm-item" v-scroll-reveal="{ delay: 550 }">
        <h1>100%</h1>
        <div class="pct-bar">
          <div class="bar b100">
            <img src="@/assets/slider.png" alt="" />
          </div>
        </div>
        <p>We'll be giving away rare Pixseals (Pixel Seals) to HODLers!</p>
      </div>
    </div>

    <div class="rm-area">
      <div class="iceberg">
        <img src="@/assets/iceberg.svg" />
      </div>
      <div class="iceberg1">
        <img src="@/assets/l1.png" />
      </div>
      <div class="iceberg2">
        <img src="@/assets/l2.png" />
      </div>
      <div class="iceberg3">
        <img src="@/assets/l3.png" />
      </div>
      <div class="iceberg4">
        <img src="@/assets/l4.png" />
      </div>
      <div class="area25">
        <span class="goal donation">Donation</span>
        <h1>25%</h1>
        <p>
          We'll be donating $10K to the
          <a href="https://theoceancleanup.com/donate/">Ocean Cleanup</a>
        </p>
      </div>
      <div class="area50">
        <span class="goal merch">Merch</span>
        <h1>50%</h1>
        <p>We'll be launching Sappy Seals merch!</p>
      </div>
      <div class="area75">
        <span class="goal donation">Donation</span>
        <h1>75%</h1>
        <p>
          We'll be donating an extra $30K to the
          <a href="https://theoceancleanup.com/donate/">Ocean Cleanup</a>
        </p>
      </div>
      <div class="area100">
        <span class="goal drop">NFT Drop</span>
        <h1>100%</h1>
        <p>HODLers will get their own rare PixSeal</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@keyframes floatberg {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0%);
  }
}
a {
  color: white;
  text-decoration: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  text-shadow: -0.06em 0 red, 0.06em 0 cyan;
  cursor: pointer;
}
.roadmap {
  margin-top: 12rem;
  .rm-area {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr 5fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "area25 area25 area50 area50"
      ". iceberg iceberg ."
      ". iceberg iceberg ."
      "area75 area75 area100 area100";
  }
  .iceberg {
    grid-area: iceberg;
    animation: floatberg 3s ease-in-out infinite;
  }
  .iceberg1,
  .iceberg2,
  .iceberg3,
  .iceberg4 {
    display: none;
  }
  .area25 {
    grid-area: area25;
    // text-align: right;
  }
  .area50 {
    grid-area: area50;
  }
  .area75 {
    grid-area: area75;
    // text-align: right;
  }
  .area100 {
    grid-area: area100;
  }
  .rm-head {
    margin-bottom: 4rem;
  }
  .rm-item,
  .rm-area {
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    //  background: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    margin: 1rem 0;
    .goal {
      padding: 0.35rem 1rem;
      border-radius: 75px;
      font-size: 0.75rem;
      margin-bottom: 1rem;
      display: inline-block;
      &.donation {
        background: #103a10;
        color: #87ff87;
      }
      &.merch {
        background: #2b103a;
        color: #d387ff;
      }
      &.drop {
        background: #3a2410;
        color: #ffc187;
      }
    }
    .pct-bar {
      background: rgba(255, 255, 255, 0.2);

      border-radius: 75px;
      .bar {
        height: 0.5rem;
        margin-bottom: 2rem;
        position: relative;
        border-radius: 8px;
        &.b25 {
          width: 25%;
          background: #0575e6; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #021b79,
            #0575e6
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #021b79,
            #0575e6
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        }
        &.b50 {
          width: 50%;
          background: #4568dc; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #b06ab3,
            #4568dc
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #b06ab3,
            #4568dc
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        }
        &.b75 {
          width: 75%;
          background: #4568dc; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #b06ab3,
            #4568dc
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #b06ab3,
            #4568dc
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        }
        &.b100 {
          width: 100%;
          background: #4568dc; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #b06ab3,
            #4568dc
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #b06ab3,
            #4568dc
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        }
        img {
          max-width: 4rem;
          position: absolute;
          top: -1.5rem;
          right: -2rem;
        }
      }
    }
    p {
      color: white;
      font-size: 0.85rem;
    }
  }
}
@media screen and(min-width:760px) {
  .roadmap {
    .rm-area {
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr 5fr;
      grid-template-columns: 0.7fr 1.6fr 0.7fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      gap: 0px 0px;
      grid-template-areas:
        "area25 iceberg1 ."
        ". iceberg2 area50"
        "area75 iceberg3 ."
        ". iceberg4 area100";

      .iceberg {
        display: none;
      }
      .goal {
        margin-bottom: 2rem;
      }
      .iceberg1,
      .iceberg2,
      .iceberg3,
      .iceberg4 {
        display: block;
        animation: floatberg 5s ease-in-out infinite;
      }
      .iceberg1 {
        grid-area: iceberg1;
        animation-delay: 0;
      }
      .iceberg2 {
        grid-area: iceberg2;
        animation-delay: 400ms;
      }
      .iceberg3 {
        grid-area: iceberg3;
        animation-delay: 800ms;
      }
      .iceberg4 {
        grid-area: iceberg4;
        animation-delay: 1200ms;
      }
      p {
        font-size: 1.2rem;
        position: relative;
      }
      .area25,
      .area100,
      .area75,
      .area50 {
        position: relative;
      }

      .area25,
      .area75 {
        &:after {
          content: "";
          position: absolute;
          bottom: auto;
          right: -130%;
          width: 230%;
          height: 2px;
          border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
          z-index: -1;
        }
      }

      .area50,
      .area100 {
        text-align: right;
        &:after {
          content: "";
          position: absolute;
          bottom: auto;
          left: -130%;
          width: 230%;
          height: 2px;
          border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
          z-index: -1;
        }
      }
    }
  }
}
</style>
