import state from "../state";
import Web3 from "web3";
import Web3Modal from "web3modal";

export default {
  async DisconnectWallet(context) {
    context.commit("SetAccounts", null);
    context.commit("SetProvider", null);
    context.commit("SetWeb3Modal", null);
    console.log(state.accounts);
    console.log(state.web3Modal);
  },
  async ConnectWallet(context) {
    try {
      context.commit(
        "SetWeb3Modal",
        new Web3Modal({
          network: "mainnet", // optional
          cacheProvider: true, // optional
          providerOptions: state.providerOptions, // required
        })
      );

      let provider = await state.web3modal.connect();

      context.commit("SetProvider", provider);
      context.commit("SetWeb3", new Web3(state.provider));

      let accounts = await state.web3.eth.getAccounts();
      context.commit("SetAccounts", accounts);
      //Loop over whitelist addresses and commit whitelist boolean to store if true
      //context.commit("SetWhitelistedAddress", true)
        await context.dispatch('CheckWhitelistedAddresses', accounts[0])
    } catch (e) {
      console.log("Connection Error!", e);
    }
  },
  async CheckWhitelistedAddresses(context, account) {
    const provider = new Web3.providers.HttpProvider(
     state.infuraAddress
    );
    let w3 = new Web3(provider);
    console.log('Checking if account is on whitelist...');
    const slContract = new w3.eth.Contract(
      state.abi,
      state.contractAddress
    );
    let inWhitelist = await slContract.methods.isAddressInWhitelist(account).call();
      if(inWhitelist) {
        console.log('address is in whitelist!');
        context.commit("SetWhitelistedAddress", true)
      }else{
        console.log('address is NOT in whitelist!');
      }
  }

};
