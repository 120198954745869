export default {
  SetProvider(state, params) {
    return (state.provider = params);
  },
  SetWeb3Modal(state, params) {
    return (state.web3modal = params);
  },
  SetWeb3(state, params) {
    return (state.web3 = params);
  },
  SetAccounts(state, params) {
    return (state.accounts = params);
  },
  SetWhitelistedAddress(state) {
    return state.whitelistedAccount = true
  }
};
