import WalletConnectProvider from "@walletconnect/web3-provider";
export default {
  mintPrompt: false,
  mintPromptWhitelist: false,
  web3Modal: null,
  provider: null,
  accounts: null,
  userAccount: null,
  web3: null,
  txInProgress: false,
  txStarted: false,
  txComplete: false,
  txHash: null,
  txFail: false,
  whitelistedAccount: false,
  abiAddress: "https://api.etherscan.io/api?module=contract&action=getabi&address=0x364C828eE171616a39897688A831c2499aD972ec",
  infuraAddress: "https://mainnet.infura.io/v3/ee5b2ba0976b41da8dcff7a538ab0ee5",
  contractAddress:  "0x364C828eE171616a39897688A831c2499aD972ec",
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "ee5b2ba0976b41da8dcff7a538ab0ee5",
      },
    },
  },
  abi: null
};
