<template>
  <header class="">
    <nav class="container">
      <div class="grid-item">
        <img class="brand" src="@/assets/logoseal.png" alt="" />
      </div>

      <div class="grid-item sm">
        <div class="socials">
          <a href="https://discord.gg/aNpmbHChVy"
            ><img src="@/assets/discord.png"
          /></a>
          <a href="https://twitter.com/SappySealsNFT"
            ><img src="@/assets/twitter.png"
          /></a>
          <a href="https://opensea.io/collection/sappy-seals/"
            ><img src="@/assets/opensea.png"
          /></a>
        </div>
        <Button @btnClicked="attemptConnect" :text="connectButtonText" />
      </div>
    </nav>
  </header>
</template>

<script>
import Button from "./ui/Button.vue";

export default {
  name: "Header",
  components: {
    Button,
  },
  methods: {
    attemptConnect() {
      let vm = this;
      if (vm.$store.state.provider == null) {
        vm.$store.dispatch("ConnectWallet");
      } else {
        console.log("dcing");
        vm.$store.dispatch("DisconnectWallet");
      }
    },
  },
  computed: {
    connectButtonText() {
      let vm = this;
      return vm.$store.state.accounts !== null
        ? vm.$store.state.accounts[0].substring(0, 5) +
            "..." +
            vm.$store.state.accounts[0].substring(
              vm.$store.state.accounts[0].length,
              vm.$store.state.accounts[0].length - 5
            )
        : "Connect";
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
h1 {
  color: $primaryColour;
}

header {
  z-index: 0;
  padding: 0.5rem 0;
  position: fixed;
  top: 0;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  left: 0;
  right: 0;
  nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: stretch;
    .brand {
      max-width: 3rem;
      text-align: left;
      border-radius: 75px;
      border: 3px solid white;
      position: relative;
      top: 2px;
    }

    .sm {
      box-sizing: border-box;
      grid-column: 3;

      .button {
        display: inline-block;
      }
      .socials {
        display: none;
        vertical-align: middle;
        margin-right: 1rem;
        a {
          display: inline-block;
          border: 0;
          padding: 0 0.5rem;
          box-sizing: border-box;
          img {
            max-width: 2rem;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
@media screen and(min-width:425px) {
  header {
    nav {
      .brand {
        max-width: 5rem;
      }
      .sm {
        .socials {
          display: inline-block;
        }
      }
    }
  }
}
</style>
