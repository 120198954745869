<template>
  <div class="home">
    <HeroSection />
    <BelowFold />
  </div>
</template>

<script>
import HeroSection from "../components/HeroSection";
import BelowFold from "../components/BelowFold";
export default {
  name: "Home",
  components: {
    HeroSection,
    BelowFold,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      this.numberDepth = this.numberDepth - 0.02;
      this.bgCol = `filter:hue-rotate(${this.scrollPosition / 20}deg)`;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  data() {
    return {
      scrollPosition: 0,
      bgCol: 0,
      numberDepth: 1,
    };
  },
};
</script>
<style lang="scss">
@keyframes per {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: -5%;
  }
  100% {
    margin-top: 0;
  }
}
.crack {
  position: absolute;
  top: 50vh;
  width: 100%;
  height: 2000px;

  .ice {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1;
  }
  .crack-floor {
    background: url("~@/assets/ice.png");
    background-size: cover;
    width: 100%;
    height: 42vw;
    position: relative;
    .bg {
      position: relative;
      top: 42vw;
      background: linear-gradient(#013378, #141424);

      width: 100%;
    }
  }
}
</style>
