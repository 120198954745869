<template>
  <div class="seal-slide">
    <div class="rocks">
      <img src="@/assets/rocks/r1.png" alt="" class="r1" />
      <img src="@/assets/rocks/r2.png" alt="" class="r2" />
      <img src="@/assets/rocks/r3.png" alt="" class="r3" />
      <img src="@/assets/rocks/r4.png" alt="" class="r4" />
      <img src="@/assets/rocks/r5.png" alt="" class="r5" />
    </div>
    <img src="@/assets/frame2.png" class="slide-frame" />
    <Carousel3d
      :autoplay="true"
      :perspective="5"
      :controls-visible="false"
      :display="1"
      :width="450"
      :height="450"
      :border="0"
      :autoplay-timeout="1500"
    >
      <Slide v-for="(slide, i) in slides" :index="i" :key="i">
        <template>
          <img :data-index="i" :src="slide.img" class="slide-image" />
        </template>
      </Slide>
    </Carousel3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  name: "Carousel",
  components: {
    Carousel3d,
    Slide,
  },
  mounted() {},
  data() {
    return {
      slides: [
        {
          _id: 0,
          name: "#123",
          img: require("@/assets/seals/1.png"),
        },
        {
          _id: 1,
          name: "#123",
          img: require("@/assets/seals/2.png"),
        },
        {
          _id: 2,
          name: "#123",
          img: require("@/assets/seals/3.png"),
        },
        {
          _id: 3,
          name: "#123",
          img: require("@/assets/seals/4.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/5.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/6.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/7.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/8.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/9.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/10.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/11.png"),
        },

        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/12.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/13.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/14.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/15.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/16.png"),
        },
        {
          _id: 4,
          name: "#123",
          img: require("@/assets/seals/17.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@keyframes float {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  50% {
    transform: translate(10px, -25px) rotate(25deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.seal-slide {
  padding: 1rem;
  border-radius: 2rem;
  .rocks {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    display: none;
    .r1,
    .r2,
    .r3,
    .r4,
    .r5 {
      position: absolute;
      animation: float 4.1s ease-in-out infinite;
    }
    .r1 {
      right: 0;
      top: 2rem;
      animation-delay: 0;
    }
    .r2 {
      right: 0;
      top: 10rem;
      animation-delay: 200ms;
    }
    .r3 {
      top: 13rem;
      animation-delay: 400ms;
    }
    .r4 {
      left: -2rem;
      animation-delay: 600ms;
    }
    .r5 {
      bottom: 0;
      animation-delay: 800ms;
    }
  }
  .carousel-3d-slider {
    height: 180px !important;
    width: 180px !important;
    .carousel-3d-slide {
      background: transparent !important;
      padding: 20px;
      height: 180px !important;
      height: 180px !important;
      .slide-image {
        position: relative;
        top: 1.5rem;
        left: 0.5rem;

        border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
        max-width: 100%;
      }
    }
  }
  position: relative;
  z-index: 1;
  .slide-frame {
    position: absolute;
    top: 2rem;
    left: 0rem;
    max-width: 200px;

    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    backdrop-filter: blur(6px);
    padding: 1rem;
    box-shadow: 0px 0px 50px rgb(255 255 255 / 12%) inset,
      0px 40px 50px #0000006e;
  }
}

@media screen and (min-width: 760px) {
  .seal-slide {
    .carousel-3d-slider {
      height: 380px !important;
      width: 380px !important;
      .carousel-3d-slide {
        height: 380px !important;
        height: 380px !important;
        .slide-image {
          transform: scale(0.8);
          top: 0.5rem;
          left: 0;
        }
      }
    }
    .slide-frame {
      position: absolute;
      top: 2rem;
      left: 0rem;
      max-width: 380px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .seal-slide {
    .rocks {
      display: block;
    }
  }
}
@media screen and (min-width: 1200px) {
  .seal-slide {
    .carousel-3d-slider {
      .carousel-3d-slide {
        background: transparent !important;
        .slide-image {
        }
      }
    }
    .slide-frame {
    }
  }
}
@media screen and (min-width: 1366px) {
}
@media screen and (min-width: 1600px) {
}
</style>
