<template>
  <div class="button" @click="$emit('btnClicked')">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    action: String,
    text: String,
  },
  methods: {
    MintDialog() {
      alert("Wallet Connected");
    },
  },
};
</script>

<style lang="scss" scoped>
.activeBtn {
  opacity: 1 !important;
}
.inactive {
  opacity: 0.4;
}
.button {
  padding: 1rem 1rem;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 0.85rem;
  box-shadow: -0.16em -0.2em red, 0.16em 0.2em cyan;
  text-align: center;
  vertical-align: middle;
  font-family: "Press Start 2P";
  transition: 0.3s ease;
  cursor: pointer;
  transform: scale(1);
  user-select: none;
  &:hover {
    background: rgba(0, 0, 0, 0.95);
    box-shadow: -0.26em -0.3em cyan, 0.26em 0.3em red;
  }
  &:active {
    box-shadow: -0.16em -0.2em red, 0.16em 0.2em cyan;
    transform: scale(0.95);
  }
  .button-icon {
    svg {
      vertical-align: bottom;
    }
  }
}

@media screen and (min-width: 760px) {
  .button {
    //max-width: 8rem;
  }
}
</style>
